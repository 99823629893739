import React from 'react';
import './style/home.css';
class Home extends React.Component {

    render() {
        return (
            <>
                <div>
                    <div className={'zf-main-middle'}>
                        <div className={'zf-cartoon'}>
                            <div className={'zf-swipe'}>
                                <div className={'zf-animation'}>
                                    <div className={'zf-img'}>
                                        <div className={'zf-db-pc'}/>
                                    </div>
                                    <div className={'zf-img'}>
                                        <div className={'zf-db-app'}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'zf-feature'}>
                            <div className={'zf-feature-row bg-white'}>
                                <div className={'zf-row-box'}>
                                    <div className={'zf-row-box-col-1'}>
                                        <div className={'zf-col-text'}>
                                            <h3 className={'zf-col-text-title'}>PC-Web版</h3>
                                            <p className={'zf-col-text-content'}>
                                                “追感漂流瓶”以承载人们的精神需求为起点，以漂流瓶和随笔为载体，以提升人们的精神追求和思想境界为宗旨。
                                                通过制定简单的规则和引导，以及充分的系统自动检测手段，尽可能的消化掉人们的负面思想和情绪，同时传递积极的，健康的，优质的思想。
                                            </p>
                                        </div>
                                    </div>
                                    <div className={'zf-row-box-col-2'}>
                                        <img className={'zf-col-img'} src={'/static/media/zfdb@pc.38ef323d70a018edefef.jpg'} alt={''}/>
                                    </div>
                                </div>
                            </div>
                            <div className={'zf-feature-row bg-grey'}>
                                <div className={'zf-row-box'}>
                                    <div className={'zf-row-box-col-2'}>
                                        <img className={'zf-col-img-app zf-fl-rt'} src={require('../../images/zfdb@app.png')} alt={''}/>
                                    </div>
                                    <div className={'zf-row-box-col-1'}>
                                        <div className={'zf-col-text'}>
                                            <h3 className={'zf-col-text-title'}>APP-Android版</h3>
                                            <p className={'zf-col-text-content'}>
                                                “追感漂流瓶”以承载人们的精神需求为起点，以漂流瓶和随笔为载体，以提升人们的精神追求和思想境界为宗旨。
                                                通过制定简单的规则和引导，以及充分的系统自动检测手段，尽可能的消化掉人们的负面思想和情绪，同时传递积极的，健康的，优质的思想。
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={'zf-feature-row bg-white'}>
                                <div className={'zf-row-box'}>
                                    <div className={'zf-row-box-col-1'}>
                                        <div className={'zf-col-text'}>
                                            <h3 className={'zf-col-text-title'}>APP-iOS版</h3>
                                            <p className={'zf-col-text-content'}>
                                                “追感漂流瓶”以承载人们的精神需求为起点，以漂流瓶和随笔为载体，以提升人们的精神追求和思想境界为宗旨。
                                                通过制定简单的规则和引导，以及充分的系统自动检测手段，尽可能的消化掉人们的负面思想和情绪，同时传递积极的，健康的，优质的思想。
                                            </p>
                                        </div>
                                    </div>
                                    <div className={'zf-row-box-col-2'}>
                                        <img className={'zf-col-img-app'} src={require('../../images/zfdb@app.png')} alt={''}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default Home;