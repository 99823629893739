import React from "react";
import './style/footer.css';

class Footer extends React.Component{

    render() {
        return (
            <div>
                <div className={'zf-main-footer'}>
                    <div className={'zf-main-footer-content'}>
                        <p className={'zf-footer-p'}>版权所有：<a href={"https://www.zhuifeel.com"}>成都追感科技有限公司</a></p>
                        <p className={'zf-footer-p'}>Copyright © 2022-2023 ZhuiFeel, All Rights Reserved.</p>
                        <p className={'zf-footer-p'}><a href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备2022028132号-1</a></p>
                        <div style={{width:'300px', height: '20px', margin:'0 0 0 0', padding:'0 0 0 0'}}>
                            <a target="_blank"
                               href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51019002005274"
                               style={{display:'inline-block', textDecoration: 'none', height:'20px', lineHeight:'20px'}}
                            >
                                <img src={require('../../icons/beian.png')} style={{float: "left"}} />
                                <p style={{float:'left', height:'20px', lineHeight:'20px', margin: '0px 0px 0px 5px', color:'#939393'}}>川公网安备 51019002005274号</p>
                            </a>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}
export default Footer;