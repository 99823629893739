import React from "react";
import './style/header.css';
import {HeaderProps} from "./props/HeaderProps";

const menuItems = [
    {id: 101, value: 'home', name: '首页'},
    {id: 102, value: 'product', name: '产品'},
    {id: 103, value: 'about', name: '关于我们'},
];
class Header extends React.Component<HeaderProps> {

    state: any = {
        selectedMenu: 'home',
    };

    onMenuClick = (item: any, e: any) => {
        const menu = item.value;
        this.setState({selectedMenu: menu});
        this.props.onMenuChange(menu, e);
    };

    render() {
        return (
            <div>
                <div className={'zf-main-header'}>
                    <div className={'zf-main-header-wrapper'}/>
                    <div className={'zf-main-header-content'}>
                        <div className={'header-left item'}>
                            <div className={'zf-profile'}>
                                <div className={'zf-logo'}/>
                                {/*<div className={'zf-name'}>*/}
                                {/*    <div className={'zf-name-cn'}>追感科技</div>*/}
                                {/*    <div className={'zf-name-en'}>zhui·feel</div>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                        <div className={'header-middle item'}>
                            <div className={'header-middle-content'}>
                                {
                                    menuItems.map((item: any) => {
                                        return (
                                            <div key={'menu_' + item.id}
                                                 className={['header-middle-item', (this.state.selectedMenu == item.value) ? 'header-middle-item-selected' : ''].join(' ')}
                                                 onClick={(e: any) => {
                                                     this.onMenuClick(item, e)
                                                 }}
                                            >
                                                {item.name}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className={'header-right item'}>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Header;