import React from "react";
import "./style/About.css";

class About extends React.Component {

    render() {
        return (
            <div className={'zf-about'}>
                <div className={'zf-about-content'}>
                    <div className={'zf-about-intro'}>
                        <h3>公司简介</h3>
                        <p>
                            &nbsp;&nbsp;&nbsp;&nbsp;成都追感科技有限公司于2022年成立于中国（四川）自由贸易试验区，以“追求至高境界，感悟美好人生”为公司的服务宗旨。
                            我们公司致力于通过公司自研产品承载人们的精神需求，传递积极的，健康的，优质的思想，以提升人们的精神追求和思想境界。
                        </p>
                    </div>
                    <div className={'zf-about-contact'}>
                        <h3>联系我们</h3>
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;公司邮箱：zhuifeel@foxmail.com</p>
                    </div>
                </div>
            </div>
        );
    }
}
export default About;