import React from 'react';
import './style/main.css'
import Home from "../home";
import Footer from "../footer";
import Header from "../header";
import Product from "../product";
import About from "../about";

class Main extends React.Component {

    state: any = {
        selectedMenu: 'home',
    };

    onMenuChange = (menu: any, e: any) => {
        this.setState({selectedMenu: menu});
    };

    render() {
        return (
            <>
                <div className={'zf-main'}>
                    <div className={'zf-main-wrap'}/>
                    <Header onMenuChange={this.onMenuChange}/>
                    <div className={'zf-main-inferior'}>
                        {
                            (this.state.selectedMenu == "home") &&
                            <>
                                <Home/>
                            </>
                        }
                        {
                            (this.state.selectedMenu == "product") &&
                            <>
                                <Product/>
                            </>
                        }
                        {
                            (this.state.selectedMenu == "about") &&
                            <>
                                <About/>
                            </>
                        }
                        <Footer/>
                    </div>
                </div>
            </>
        );
    }
}

export default Main;