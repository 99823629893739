import React from "react";
import "./style/Product.css";

class Product extends React.Component {

    render() {
        return (
            <div className={'zf-product'}>
                <div className={'zf-product-content'}>
                    <div className={'zf-product-intro'}>
                        <h3>PC-Web版</h3>
                        <div>
                            <img src={require('../../images/pc.JPG')}/>
                        </div>
                    </div>
                    <div className={'zf-product-intro'}>
                        <h3>APP-Android版</h3>
                        <div>
                            <img src={require('../../images/android.JPG')}/>
                        </div>
                    </div>
                    <div className={'zf-product-intro'}>
                        <h3>APP-iOS版</h3>
                        <div>
                            <img src={require('../../images/ios.JPG')}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Product;